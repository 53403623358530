import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby';
import financia_features from '../../data/financia_features.json';
import financia_why from '../../data/financia_why.json';
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../../components/seo';

export default function Financia() {
    const financia_hero = '../../assets/images/products/financia1.png';

    return (
        <Layout >
            <Seo title="Financia" />
            <div className="flex items-stretch items-center financia-hero-card m-4 lg:m-16 lg:mt-10 2xl:mx-28 h-full relative z-30 overflow-visible relative">
                {/* <div className="flex hero-card p-4 lg:p-16 lg:mt-10 h-full relative z-30 overflow-visible relative"> */}
                <div id="financia_hero" className="z-30 pb-10 md:pb-20">
                    {/* w-7/12 sm:w-4/12 lg:w-4/12 2xl:w-5/12 */}
                    {/* <main className="mt-10 w-full sm:w-8/12 md:w-7/12 lg:w-8/12 xl:w-7/12 3xl:w-7/12 px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28"> */}
                    <main className="mt-10 w-full px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
                        <div className="text-center md:text-left">
                            <h1 className="font-extrabold text-dark-tf text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl w-fulltracking-wide ">
                                <span className="block xl:inline leading-relaxed md:leading-14 lg:leading-15">Boost your HR capabilities with <strong className="text-purple-tf">Financia</strong></span>
                                {/* <span className="block text-blue-tf xl:inline leading-relaxed md:leading-14 lg:leading-15"> TimeFree</span> */}
                                {/* <span className="block xl:inline leading-relaxed md:leading-14 lg:leading-15"> digital solutions</span> */}
                            </h1>
                            <p className="mt-5 sm:mt-5 md:mt-10 lg:mt-15 pl-1 font-normal text-base text-notsodark-tf text-lg md:text-xl xl:text-2xl 4xl:text-3xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-loose">
                                an HRIS platform that combines the power of data analytics and the cloud to give your
                                business 24x7 access to your HR and accounting needs including payroll,
                                employee management, billings, and many others.
                            </p>
                        </div>
                        <div className="mt-8 flex lg:mt-10 lg:flex-shrink-0 justify-center md:justify-start">
                            <div className="inline-flex rounded-md shadow-xl shadow-purple-tf/20">
                                <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-purple-tf hover:bg-purple-trans-tf">
                                    Request a demo
                                </Link>
                            </div>
                        </div>
                    </main>
                </div>
                {/* <div id="financia_hero_image" className="filter drop-shadow-xl flex justify-end overflow-visible absolute top-20 xxs:top-20 sm:top-0 md:top-10 lg:top-10 xl:top-28 right-0 lg:right-5 xl:right-0 w-7/12 sm:w-4/12 lg:w-4/12 2xl:w-5/12 hidden sm:block md:block z-10 opacity-50 xs:opacity-100"> */}
                <div id="financia_hero_image" className="filter drop-shadow-xl flex self-center overflow-visible right-0 content-center w-full 3xl:w-1/2 4xl:w-6/12 hidden md:block z-10 opacity-50 xs:opacity-100">
                    <StaticImage
                        className="flex object-contain overflow-visible"
                        src={financia_hero}
                        placeholder="tracedSVG"
                        alt=""
                    />
                </div>
            </div>
            <div id="financia_hero_image_alt" className="block md:hidden w-full flex justify-center">
                <StaticImage
                    className="flex object-contain overflow-visible w-3/4"
                    src={financia_hero}
                    placeholder="tracedSVG"
                    alt=""
                />
            </div>
            <div id="financia_features" className="bg-white py-8 mt-10">
                <div className="flex flex-col items-center m-4 lg:m-16 2xl:mx-28">
                    <div className="w-3/4 md:w-5/7">
                        <h4 className="text-3xl 4xl:text-4xl font-bold text-dark-tf text-center">The easy way to manage your workforce.</h4>
                        <p className="text-xl 4xl:text-2xl text-dark-tf text-center mt-3">As your organization grows, so does the need for an end-to-end HR Information System. From member registration and onboarding to leave management, payroll, and billings, our HRIS platform has been designed with the modern, proactive company in mind.</p>
                    </div>
                    <div className="mt-16 flex-none lg:flex">
                        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-8 flex justify-between items-stretch">
                            {financia_features.map((item) => (
                                <div key={item.title}>
                                    <div className="text-center md:text-left bg-financia-purple p-6 rounded-2xl self-stretch h-full md:flex gap-x-5">
                                        <div className="flex justify-center md:items-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-financia-darkpurple" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                {item.svg.map((svg, i) => (
                                                    <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                                ))}
                                            </svg>
                                        </div>
                                        <div className="">
                                            <h3 className="head-font font-semibold  text-xl md:text-xl 4xl:text-3xl text-financia-darkpurple ">{item.title}</h3>
                                            <p className="font-normal text-lg 4xl:text-2xl mt-2 text-gray-700">{item.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div className="filter drop-shadow-xl h-1/2">
                            <img
                                className="flex object-contain overflow-visible h-1/2"
                                src={qsafe_hero}
                                alt=""
                            />
                        </div> */}
                    </div>
                </div>
            </div>

            <div id="financia_why" className="mx-4 lg:mx-16 my-28 2xl:mx-28">
                <div className="flex flex-col items-center ">
                    <div className="w-full">
                        <h4 className="text-3xl 4xl:text-4xl font-bold text-dark-tf text-center">Why do businesses choose <strong className="text-financia-darkpurple">Financia</strong>?</h4>
                    </div>
                    <div className="mt-10 flex-none lg:flex">
                        {/* <div className="filter drop-shadow-xl">
                            <img
                                className="flex object-contain overflow-visible h-full"
                                src={qsafe_hero}
                                alt=""
                            />
                        </div> */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-0 flex justify-between items-stretch ">
                            {financia_why.map((item) => (
                                <div key={item.key}>
                                    <div className="text-center md:text-left p-6 rounded-2xl self-stretch h-full gap-x-2">
                                        <div className="flex justify-center md:items-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-financia-darkpurple" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                {item.svg.map((svg, i) => (
                                                    <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                                ))}
                                            </svg>
                                        </div>
                                        <div className="mt-4 flex justify-center">
                                            <p className="font-medium text-lg 4xl:text-2xl mt-1 text-gray-700 text-center w-3/4">{item.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div id="financia_cta" className="bg-white">
                <div className="max-w-4xl mx-4 md:mx-auto py-12 px-4 lg:py-16 lg:px-16 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center md:text-left">
                        <span className="block text-gray-700">Want to see this in action?</span>
                        <span className="block text-financia-darkpurple "><Link to="/contact">Request a demo now.</Link></span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 justify-center md:justify-start">
                        <div className="inline-flex rounded-md shadow-xl shadow-purple-tf/20">
                            <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-financia-darkpurple  hover:bg-financia-purple hover:text-gray-600">
                                Contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
